import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Icon from "@components/atoms/Icon"
import styled from "styled-components"
import { flatten, map, orderBy } from "lodash"

const Page = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
  font-size: 11pt;
  margin: 10mm auto;
  height: 297mm;
  width: 210mm;
  color: black;
  overflow: hidden;

  h1,
  h2 {
    font-weight: 400;
    text-transform: uppercase;
  }

  h2 {
    border: 1px solid currentColor;
    border-width: 1px 0 1px 0;
    font-size: 1.25em;
    margin: 2em 0 1em;
    padding: 0.25em 0;
  }

  h3 {
    font-size: 1em;
    margin: 0 0 0.25em;
    text-transform: uppercase;
  }

  i {
    display: block;
    margin: 0 0 0.25em;
  }

  @media print {
    background: initial;
    border: initial;
    border-radius: initial;
    box-shadow: initial;
    margin: 0;
    min-height: initial;
    page-break-after: always;
    width: initial;
  }
`
interface FillProps {
  readonly fill?: boolean
}
const Row = styled.div<FillProps>`
  display: flex;
  ${({ fill }) => fill && "height: 100%;"}
`

const Col = styled.div<FillProps>`
  ${({ fill }) => fill && "height: 100%;"}
  flex: 1;
  padding: 0 2em 2em;
`
const LeftCol = styled(Col)`
  position: relative;
  flex: 0 0 33% !important;
  background-color: rgb(230, 230, 234);
`
const Profile = styled(LeftCol)`
  display: grid;
  padding: 0;
`

const Title = styled.fieldset`
  margin-top: 2em;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.colors.teal04};
  color: ${({ theme }) => theme.colors.teal04};
  h1 {
    transform: rotate(180deg);
    font-size: 3em;
  }
  legend {
    margin: auto;
    transform: rotate(180deg);
    padding: 0 1em;
  }
`
const Contact = styled.div`
  margin: 2em 0;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      span {
        border: 1px solid currentColor;
        border-width: 1px 0 1px 0;
        padding: 0.25em 0;
      }
      &:not(:last-of-type) {
        margin-right: 1em;
      }
    }
  }
`

const IconBox = styled.div`
  color: white;
  background: black;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-right: 0.5em;
`

const Bio = styled.div`
  background-color: ${({ theme }) => theme.colors.teal04};
  opacity: 0.6;
  color: white;
  margin: 0 -2em -2em;
  padding: 2em;
`

const ProfileTitle = styled.div`
  height: ${({ height }) => height}px;
  left: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  padding: 0 2em 2em;
  color: white;
  background-color: ${({ theme }) => theme.colors.teal04};
  opacity: 0.6;
`

const TimeLine = styled.ul`
  border-left: 2px solid black;
  list-style: none;
  padding: 0 0 0 1em;
  width: 86%;
  margin-left: auto;
  margin-top: 0;
`
const TimeLineBlock = styled.li`
  position: relative;
  margin-bottom: 2em;
  &:before,
  &:after {
    display: block;
    position: absolute;
  }
  &:before {
    content: attr(data-date);
    left: -86px;
    max-width: 51px;
    text-align: right;
  }
  &:after {
    left: -19px;
    background: #313534;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    content: "";
    top: 0;
  }
`
const Skill = styled.div`
  width: 100%;
  background: #ddd;
  &:after {
    content: "";
    display: block;
    padding: 5px;
    width: ${({ level }) => level}%;
    background-color: ${({ theme }) => theme.colors.teal04};
  }
`

const SkillContainer = styled.div`
  > div {
    margin-top: 1em;
    > div {
      margin-bottom: 0.5em;
    }
  }
`
const EduBlock = styled.div`
  margin-bottom: 1em;
`

const SocialItem = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.teal04};
  div {
    font-size: 8pt;
    word-break: break-all;
    margin-left: 1em;
    color: black;
    div {
      &:first-of-type {
        text-transform: uppercase;
        margin-bottom: 0.25em;
        font-weight: 400;
      }
    }
  }
`

export default function Resume() {
  const { strapiProfile } = useStaticQuery(graphql`
    {
      strapiProfile {
        bio
        name
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, formats: WEBP, placeholder: BLURRED)
            }
          }
        }
        title
        skillGroups {
          description
          skills {
            name
            level
          }
        }
        socialMedia {
          ref
          name
          url
        }
        education {
          qualification
          provider
          year
        }
        experience {
          id
          date
          position
          company
          details
        }
      }
    }
  `)

  const skillsMap = flatten(map(strapiProfile.skillGroups, "skills"))

  return (
    <>
      <Page>
        <Row>
          <Profile>
            <GatsbyImage
              alt={`image of ${strapiProfile.name}`}
              image={
                strapiProfile.image.localFile.childImageSharp.gatsbyImageData
              }
              loading="eager"
              objectFit="cover"
              style={{
                gridArea: "1/1",
                borderTopLeftRadius: "5px 5px",
              }}
            />
            <ProfileTitle height={139}>
              <h2>Profile</h2>
            </ProfileTitle>
          </Profile>
          <Col>
            <Title>
              <h1>Luke Grayland</h1>
              <legend>{strapiProfile.title}</legend>
            </Title>
            <Contact>
              <ul>
                <li>
                  <IconBox>
                    <Icon size={15} name="mail" />
                  </IconBox>
                  <span>lgrayland@gmail.com</span>
                </li>
                <li>
                  <IconBox>
                    <Icon size={15} name="home" />
                  </IconBox>
                  <span>Malaga, Spain</span>
                </li>
              </ul>
            </Contact>
            <Bio>{strapiProfile.bio}</Bio>
          </Col>
        </Row>
        <Row fill>
          <LeftCol>
            <div>
              <h2>skills</h2>
              <SkillContainer>
                {orderBy(skillsMap, ({ level }) => level || 0, "desc").map(
                  skill => (
                    <div key={skill.name}>
                      <div>{skill.name}</div>
                      <Skill level={skill?.level || 0} />
                    </div>
                  )
                )}
              </SkillContainer>
            </div>
          </LeftCol>
          <Col>
            <div>
              <h2>Work Experience</h2>
              <TimeLine>
                {strapiProfile.experience.slice(0, 2).map(item => (
                  <TimeLineBlock key={item.id} data-date={item.date}>
                    <h3>{item.position}</h3>
                    <i>{item.company}</i>
                    <div
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{ __html: item.details }}
                    />
                  </TimeLineBlock>
                ))}
              </TimeLine>
            </div>
          </Col>
        </Row>
      </Page>
      <Page>
        <Row fill>
          <LeftCol>
            <div>
              <h2>education</h2>
              {strapiProfile.education.map(item => (
                <EduBlock key={item.id}>
                  <h3>{item.qualification}</h3>
                  <i>{item.provider}</i>
                  <div>{item.year}</div>
                </EduBlock>
              ))}
            </div>
            <div>
              <h2>social</h2>
              {strapiProfile.socialMedia.map(link => (
                <SocialItem key={link.ref}>
                  <Icon name={link.ref} size={45} />
                  <div>
                    <div>{link.name}</div>
                    <div>{link.url}</div>
                  </div>
                </SocialItem>
              ))}
            </div>
          </LeftCol>
          <Col>
            <div>
              <h2>Work Experience (continued)</h2>
              <TimeLine>
                {strapiProfile.experience.slice(2).map(item => (
                  <TimeLineBlock key={item.id} data-date={item.date}>
                    <h3>{item.position}</h3>
                    <i>{item.company}</i>
                    <div
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{ __html: item.details }}
                    />
                  </TimeLineBlock>
                ))}
              </TimeLine>
            </div>
            {/* <div>
              <h2>hobbies / interests</h2>
            </div> */}
          </Col>
        </Row>
      </Page>
    </>
  )
}
